<template>
  <div class="time-log">
    <vs-input
      :label="lang.general.time.years[languageSelected]"
      type="number"
      required
      :min="0"
      class="mr-6 time-input"
      v-model="years"
      @keypress="isDigit($event)"
      @input="handleTime"
      :disabled="readOnly"
    />

    <vs-input
      :label="lang.general.time.weeks[languageSelected]"
      type="number"
      required
      :min="0"
      class="mr-6 time-input"
      v-model="weeks"
      @keypress="isDigit($event)"
      @input="handleTime"
      :disabled="readOnly"
    />

    <vs-input
      :label="lang.general.time.days[languageSelected]"
      type="number"
      required
      :min="0"
      class="mr-6 time-input"
      v-model="days"
      @keypress="isDigit($event)"
      @input="handleTime"
      :disabled="readOnly"
    />

    <vs-input
      :label="lang.general.time.hours[languageSelected]"
      type="number"
      required
      :min="0"
      max="23"
      class="mr-6 time-input"
      v-model="hours"
      @keypress="isDigit($event)"
      @input="handleTime"
      :disabled="readOnly"
    />

    <vs-input
      :label="lang.general.time.minutes[languageSelected]"
      type="number"
      required
      :min="0"
      max="23"
      class="mr-6 time-input"
      v-model="minutes"
      @keypress="isDigit($event)"
      @input="handleTime"
      :disabled="readOnly"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'TimeLog',
  props: {
    value: Number,
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      years: 0,
      weeks: 0,
      days: 0,
      hours: 0,
      minutes: 0
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected'])
  },
  methods: {
    handleTime() {
      const yearsToMin = this.years ? parseInt(this.years * 365 * 24 * 60) : 0
      const weeksToMin = this.weeks ? parseInt(this.weeks * 7 * 24 * 60) : 0
      const daysToMin = this.days ? parseInt(this.days * 24 * 60) : 0
      const hoursToMin = this.hours ? parseInt(this.hours * 60) : 0
      const minutes = this.minutes ? parseInt(this.minutes) : 0

      const value = parseInt(
        yearsToMin + weeksToMin + daysToMin + hoursToMin + minutes
      )
      this.$emit('input', value)
    },
    initTime() {
      let value = this.value

      const units = {
        years: 365 * 24 * 60,
        weeks: 7 * 24 * 60,
        days: 24 * 60,
        hours: 60,
        minutes: 1
      }
      let result = { years: 0, weeks: 0, days: 0, hours: 0, minutes: 0 }

      for (let name in units) {
        result[name] = Math.floor(value / units[name])
        value %= units[name]
      }

      this.years = result['years']
      this.weeks = result['weeks']
      this.days = result['days']
      this.hours = result['hours']
      this.minutes = result['minutes']
    },
    isDigit(evt) {
      evt = evt ? evt : window.event
      const charCode = evt.which ? evt.which : evt.keyCode

      if (charCode >= 48 && charCode <= 57) {
        return true
      } else {
        evt.preventDefault()
      }
    }
  },
  mounted() {
    this.initTime()
  },
  watch: {
    value() {
      this.initTime()
    },
  },
}
</script>

<style lang="scss" scoped>
.time-log {
  display: flex;
  align-items: center;

  .time-input {
    width: 80px;
  }
}
</style>
